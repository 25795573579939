<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { i18n } from "vue-lang-router";

export default {
    name: 'faq',

    data() {
        return {
            name: '',
            email: '',
        };
    },

    created() {
        document.title = "Vanliga frågor om Tempus Bildbyrå";
    },

    computed: {
        ...mapGetters(['isLoading']),

    },

    methods: {
        send() {
            if (this.name == '' || this.email == '') {
                alert('Please complete all fields');
                return;
            }
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(this.email)) {
                alert('Email field must have email form');
                return;
            }
            axios.post(`${process.env.VUE_APP_BACKEND_URL}/api/newsletter/save`,
                { name: this.name, email: this.email }).then(() => {
                    this.name = "";
                    this.email = "";
                    alert('Saved');
                });
        },
    },

    components: {},
};
</script>

<template>
    <div class="illustration-info-container">
        <div class="illustration-info">
            <div class="content">
                <div class="header p-4">
                    <h1 class="text-3xl">Vanliga frågor</h1>
                </div>
                <br>
                <div class="content-paragraph">
                    <p><b>Vilken prismodell tillämpar ni?</b>
                    <br/><br/>
                    Vi har sju priskategorier och priserna baseras huvudsakligen på bildernas storlek. Generellt sett har små bilder lägre pris och större bilder högre pris. För tillfället tillämpas inga särskilda priser för olika användningsområden.
                    <br/><br/>
                    <b>Jag vill köpa många bilder. Kan jag få mängdrabatt?</b>
                    <br/><br/>
                    Självklart! För tillfället har vi av tekniska skäl inga färdiga bildpaket eller abonnemang, men vi träffar gärna sådan överenskommelser. För att mängdrabatt skall komma ifråga bör det dock handla om minst 20 bilder vid ett köptillfälle eller minst 100 bilder under ett år. Kontakta oss och berätta om dina behov!
                    <br/><br/>
                    <b>Går det att få en bild i högre upplösning?</b>
                    <br/><br/>
                    Ja, det kan ofta ordnas, för en särskild avgift. Kontakta oss.
                    </p>
                </div>

                <div class="content-paragraph">
                     <img src="@/assets/differentsizes.jpg" style="border: 1px solid grey; padding: 5px;">
                </div>

                <div class="content-paragraph">
                    <p><b>Varför har bilderna så olika format och olika storlek?</b>
                        <br/><br/>
                        En del bilder är kvadratiska, en del väldigt avlånga, en del runda och nästan alla har oregelbundna kanter. Förklaringen ligger i att dåtidens illustratörer inte var begränsade till ett visst format så som fotografer är med kameror. Det var i första hand motivet som fick avgöra vilket format bilden skulle ha. Visst beskär vi eller frilägger fotografier i dag, men påfallande ofta får det normala fotografiformatet avgöra bildens proportioner när vi publicerar den. Vad gäller bildernas storlek så trycktes de i mycket varierande storlek i de böcker eller tidningar som de är hämtade ifrån. Bland bilderna finns allt ifrån avbildningar av mynt i naturlig storlek till panoramavyer av städer på femtio centimeter eller mer i bredd.
                        <br/><br/>
                        <b>Har ni inga färgbilder?</b>
                        <br/><br/>
                        Arkivet består av bokillustrationer från framför allt 1800-talet och färgtryck var mycket ovanligare då än nu. De flesta bokillustrationerna vid denna tid var trägravyrer som trycktes i svartvitt. Det fanns litografier i färg, men de förekom mycket mer sällan. En populär form av bilder är förstås målningar, men vi har inte några fotografier av målningar i arkivet. Tempus Bildbyrå har valt att som nisch ha just bokillustrationer, eftersom det finns en sådan mångfald av fina illustrationer i böcker och tidningar som i många fall är svåra att få tag på.
                        <br/><br/>
                        <b>Om jag har köpt en bild från er, får jag då lägga upp den på Internet?</b>
                        <br/><br/>
                        Ja, du får publicera den på Internet, men inte i dess originalstorlek eftersom det då är lockande för någon annan att kopiera bilden. Du måste därför minska bildens upplösning till 72 ppi (bildpunkter per tum), men vi kan också hjälpa dig med detta. Notera också att du inte får göra bilden tillgänglig på Internet på något sätt som för andra framstår som att den är fri att kopiera eller ladda ned.
                        <br/><br/>
                        <b>Måste jag använda bildens bildtext som anges på Tempus Bildbyrås webbplats?</b>
                        <br/><br/>
                        Nej, det behöver du inte. Syftet med denna text är bara att beskriva motivet så pass väl att du vet huruvida bilden uppfyller ditt behov eller ej. Beskrivningen bygger på originalets text samt redaktionens efterforskningar och är oftast starkt standardiserad för att underlätta sökbarheten. När du använder bilden skall du självklart skriva en bildtext som passar i det sammanhang du använder bilden. Om du är intresserad av att ta del av ett längre stycke av texten i den bok eller tidning där bilden ursprungligen publicerades försöker vi gärna hjälpa dig. De flesta böckerna finns tillgängliga i vårt arkiv.
                    </p>
                </div>

                <div class="content-paragraph">
                    <img src="@/assets/books.jpg" style="border: 1px solid grey; padding: 5px;">
                </div>

                <div class="content-paragraph">
                   <p>
                     <b>Kan jag få veta från vilken bok eller tidning en bild är hämtad?</b>
                     <br/><br/>
                     Ja, det är bara att kontakta oss. Ibland kan bildens ursprung avgöra om den är relevant att använda och i andra fall (kanske främst i vetenskapliga sammanhang) är det kutym att redogöra för var en bild varit ursprungligen publicerad.
                     <br/><br/>
                     <b>Måste jag ange upphovspersonernas namn?</b>
                     <br/><br/>
                     I enlighet med ideell upphovsrätt har upphovspersoner så kallad namngivningsrätt, vilket innebär att namnet skall anges vid användning av bilden. Regleringen av ideell upphovsrätt skiljer sig åt mellan olika länder. I en del länder gäller för ideell upphovsrätt samma sak som för ekonomisk, det vill säga att den är skyddad i ett visst antal år efter upphovspersonens död. I andra länder (såsom Sverige) gäller dock den ideella upphovsrätten för evigt. Tempus Bildbyrå rekommenderar att du respekterar den ideella upphovsrätten och i samband med användning av bild anger upphovspersonernas namn. De har lämnat ett värdefullt bidrag till svenskt och europeiskt kulturarv och förtjänar att namnges.
                    </p>
                </div>
               
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.illustration-info-container {
    text-align: left;
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.05);

    .illustration-info {
        margin: 0 25%;
        // height: 100vh;
        background-color: #fff;

        @media only screen and (max-width: 992px) {
            margin: 0 20px;
            width: 100%;
            height: 100%;
        }

        @media only screen and (max-width: 600px) {
            width: auto;
        }

        // @media screen and (max-width: $bp-mobile) {
        //   width: 100%;
        //   margin: 0;
        // }

        .header {
            span {
                margin: 0 1em;
                cursor: pointer;
            }

            h3 {
                padding: 1em;
            }
        }

        .content {
            padding: 1em;

            h1 {
                font-size: 30px;
                font-weight: 500;
                line-height: 36px;
            }

            h2 {
                font-size: 22px;
                font-weight: 500;
                line-height: 36px;
            }

            .content-paragraph {
                font-size: 16px;
                font-weight: 500;
                line-height: 29px;

                ul {
                    list-style-type: disc;
                    padding-left: 20px;
                }

                a {
                    text-decoration: underline;
                    color: #1c1c68;
                }

                ol {
                    list-style-type: auto;
                    padding-left: 20px;
                }
            }

             .borderedImage{
                border: 1px solid gray;
                text-align: center;
                font-size: 14px;
            }

            .split-content {
                display: flex;
                flex-direction: row;
                gap: 30px;

                img {
                    width: 40%;
                }

                @media only screen and (max-width: 992px) {
                    flex-direction: column;

                    img {
                        width: 100%;
                    }
                }


            }

            div {
                padding: 1em;
            }

            .about {
                p {
                    margin: 0;
                    font-size: 0.9em;
                    line-height: 1.4em;
                }
            }

            .category {
                div {
                    padding: 0;
                    margin: 1em 0;

                    &:last-of-type {
                        margin-bottom: 0;
                        border-bottom: none;
                    }
                }
            }

            .description {
                background-color: #dfe6e9;
                padding-left: 0;
                padding-right: 0;
            }

            .tags {
                display: block;

                .badge {
                    display: inline-block;
                    font-size: 0.75em;
                    padding: 0.5em 0.75em;
                    border-radius: 5px;
                    color: #fff;
                    margin: 0 0.5em 0.5em 0;
                    background-color: #1c1c68;
                }
            }
        }
    }


}</style>
